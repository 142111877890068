import { WorkshopReservation } from "@/models/workshop-reservation.model";
import { Workshop } from "@/models/workshop.model";
import WorkshopsService from "@/services/WorkshopsService";
import Vue from "vue";

interface WorkshopsState {
  all: Workshop[];
  current: Workshop;
  reservations: WorkshopReservation[];
}

const Pages = {
  namespaced: true,
  state: {
    all: [],
    current: new Workshop(),
    reservations: [],
  },
  getters: {
    all: (state: WorkshopsState) => state.all,
    reservations: (state: WorkshopsState) => state.reservations,
    current: (state: WorkshopsState) => state.current,
  },
  mutations: {
    SET_ALL(state: WorkshopsState, pages: any) {
      state.all = pages;
    },
    SET_RESERVATIONS(state: WorkshopsState, reservations: any) {
      state.reservations = reservations;
    },
    SET_CURRENT(state: WorkshopsState, page: Workshop) {
      Vue.set(state, "current", page);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const pages = await WorkshopsService.fetchAllUnpaginated();
      commit("SET_ALL", pages);
    },
    async fetchReservationsForWorkshop({ commit }: any, workshop: string) {
      const reservations = await WorkshopsService.fetchWorkshopReservations(
        workshop
      );
      commit("SET_RESERVATIONS", reservations);
    },
    async create({ commit }: any, data: Workshop) {
      const response = await WorkshopsService.create(data);
      commit("SET_CURRENT", response);
      return response;
    },
    async update({ commit }: any, data: Workshop) {
      const response = await WorkshopsService.update(data.id as string, data);
      commit("SET_CURRENT", response);
      return response;
    },
    async delete({ commit }: any, id: string) {
      const response = await WorkshopsService.delete(id);
    },
    async fetchById({ commit }: any, id: string) {
      const response = await WorkshopsService.fetchOne(id);
      commit("SET_CURRENT", response);
      return response;
    },
  },
} as any;

export default Pages;
