import { WorkshopReservation } from "@/models/workshop-reservation.model";
import { Workshop } from "@/models/workshop.model";
import { PaginatedResult } from "@/shared/types/paginated-result.class";
import axios from "../plugins/axios.plugin";
import BaseService from "./base-service";

class WorkshopsService extends BaseService<Workshop> {
  get endpoint() {
    return "/workshops";
  }
  async fetchWorkshopReservations(
    workshop: string
  ): Promise<WorkshopReservation[]> {
    console.log(workshop);
    const response = await axios.get<WorkshopReservation[]>(
      `/workshop-reservations/${workshop}`
    );

    return response.data;
  }
}

export default new WorkshopsService();
