import { BaseModel } from "./base.model";

export class Workshop extends BaseModel {
  constructor(
    public active: boolean = true,
    public starts_on: Date = new Date(),
    public title: { [lang: string]: string } = {},
    public description: { [lang: string]: string } = {},
    public limit_seats = false,
    public number_of_seats = 0,
    public stops_on?: Date,
    public location: { [lang: string]: string } = {},
    public group?: string
  ) {
    super();
  }
}
